import * as React from 'react';
import Link from 'next/link';
import clsx from 'clsx';
import Image from 'next/legacy/image';

import { ProductCollectionPage } from 'models/settings';
import { getImageUrl } from 'lib/util/imageUrl';
import { encodeCategoryNameForURI } from 'lib/util/encodeCategoryNameForURI';
import { appendQueryString } from 'lib/util/appendQueryString';
import { useQueryString } from 'hooks/useQueryString';

import styles from './CategoryCard.module.css';
import baseStyles from '../../base.module.css';

type Props = {
  category: ProductCollectionPage;
};

export const CategoryCard = ({ category }: Props) => {
  const queryString = useQueryString();

  return (
    <li className={styles['item__li']}>
      <Link
        href={appendQueryString(`/top/${encodeCategoryNameForURI(category.name)}`, queryString)}
      >
        <div className={styles['item__li__pic']}>
          {category.thumbnail_image_url && (
            <Image
              layout="fill"
              src={category.thumbnail_image_url}
              loader={({ src, width }) => getImageUrl(src, width)}
              alt="banner"
            />
          )}
          <div className={styles['item__li__sub']}>
            <div className={clsx(styles['item__li__sub__erea'], baseStyles['color-bg'])}>
              <svg
                className={baseStyles['color-fill']}
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
              >
                <path d="M0,32V0H32A32,32,0,0,0,0,32Z" />
              </svg>
              <svg
                className={baseStyles['color-fill']}
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
              >
                <path d="M0,32V0H32A32,32,0,0,0,0,32Z" />
              </svg>
            </div>
          </div>
        </div>
        <div className={styles['item__li__info']}>
          <h2 className={clsx(styles['item__li__info__ttl'], 'maincolor-txt')}>
            {category.display_name || category.name}
          </h2>
          <p className={styles['item__li__info__desc']}>{category.description}</p>
        </div>
      </Link>
    </li>
  );
};
